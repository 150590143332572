<div class="profile-avatar-dropdown">
  <div class="d-flex align-items-center justify-content-center">
    <div class="position-relative">
      <img (click)="navigateToProfile()" class="avatar cursor ms-2 me-1"
           src="{{ userProfile?.profileLogo || 'https://ih1.redbubble.net/image.1046392278.3346/aps,504x498,small,transparent-pad,400x400,f8f8f8.jpg' }}">
      <i class="position-absolute p-1 bg-success border rounded-circle">
        <span class="visually-hidden">New alerts</span>
      </i>
    </div>
    <div class="dropdown">
      <a class="nav-link dropdown-toggle fw-bold" data-bs-toggle="dropdown" href="#">
        {{ userProfile?.userFullName || '...' }}
      </a>
      <div class="dropdown-menu">
        <div>
          <li cDropdownHeader class="bg-light fw-bold">Account</li>
          <section>
            <li (click)="navigateToProfile()">
              <a class="dropdown-item" href="javascript:">
                <i class="fa-solid fa-user me-3" [style.width]="'24px'"></i>
                <span>My Profile</span>
              </a>
            </li>
            <li (click)="navigateToPassword()">
              <a class="dropdown-item" href="javascript:">
                <i class="fa-solid fa-key me-3" [style.width]="'24px'"></i>
                <span>Change Password</span>
              </a>
            </li>
            <li (click)="logout()">
              <a class="dropdown-item" href="javascript:">
                <i class="fa-solid fa-lock me-3" [style.width]="'24px'"></i>
                <span>Logout</span>
              </a>
            </li>
          </section>
        </div>
        
        <li cDropdownHeader class="fw-bold mt-3">Preferences</li>

        <section class="category-wrapper">
          <div *ngFor="let partner of partners" class="bg-light-subtle" [ngClass]="{'mt-2 mb-4 py-2 border-top border-bottom': partner?.tenants?.length}">
            <ng-template [ngIf]="partner?.tenants?.length" [ngIfElse]="partnersEmpty">
              <!--<li cDropdownHeader class="bg-light fw-bold">
                {{ partner?.name }}
              </li>-->
              <li *ngFor="let tenant of partner?.tenants">
                <p
                class="dropdown-item d-flex align-items-center"
                [ngClass]="{'active fw-bold': tenant?.id === selectedTenant}"
                (click)="onDropdownChange('selectedTenant', tenant?.id)"
              >
                <a class="col-9 text-truncate d-flex align-items-center text-decoration-none" [ngClass]="{'text-white': tenant?.id === selectedTenant}" href="#">
                  <img class="me-3" src="{{ tenant.logo }}" alt="{{ tenant.name }}-logo" width="24px" />
                  <span>{{ tenant.name }}</span>
                </a>
                <span class="col-3" (click)="onEyeClick($event, tenant?.id)" title="View Details" [ngClass]="{'up-icon': visibleTenantId === tenant?.id , 'text-end' : visibleTenantId !== tenant?.id}">
                 <i class="fa-solid fa-caret-down" ></i>
                </span>                
              </p>
                <div class="dropdown-menu"  [ngClass]="{'show': visibleTenantId === tenant?.id}">
                  <div>
                    <section>
                      <li>
                        <a class="dropdown-item clipboard-icon" href="javascript:">
                          {{ 'TenantId: ' + tenant?.id }}
                          <button class="copy-btn" (click)="copyToClipboard($event,tenant?.id)">
                            <i class="fa fa-copy"></i>
                          </button>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item clipboard-icon" href="javascript:">
                          {{ 'EnvironmentId: ' + tenant?.environment.id }}
                          <button class="copy-btn" (click)="copyToClipboard($event,tenant?.environment.id)">
                            <i class="fa fa-copy"></i>
                          </button>
                        </a>
                      </li>
                    </section>
                  </div>
                </div>
              </li>
            </ng-template>
            <ng-template #partnersEmpty>
              <!--<li cDropdownHeader class="bg-light text-center mt-2">
                <i class="fa-solid fa-triangle-exclamation me-1"></i> {{ partner?.name }} has no tenant
              </li>-->
            </ng-template>
          </div>
        </section>

        <li cDropdownHeader class="bg-light fw-bold pt-2 cursor" (click)="reset()">Reset</li>
      </div>
    </div>

  </div>
</div>
