export function devToolNotifify(toaster){
            let command;
            if (navigator.userAgent.indexOf('Mac OS X') != -1) {
              // mac detected
              command = 'Command+Option+K';
            } else {
              // windows detected
              command = 'Ctrl+Shift+I';
            }
            toaster.show(
              `See chrome devtools (${command}) for more details`,
              '',
              {
                positionClass: 'toast-bottom-right',
                toastClass: 'ngx-toastr rounded',
              }
            );
    
}