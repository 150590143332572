import { Component } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";

import { OidcAuthService } from "../../../../core/oidc/oidc-auth.service";
import { IdentityAdminService } from "../../../../views/settings/identity-admin.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-profile-dropdown',
  templateUrl: './user-profile-dropdown.component.html',
  styleUrls: ['./user-profile-dropdown.component.scss']
})
export class UserProfileDropdownComponent {
  data$: any;

  userProfile: any;
  selectedPartner: any;
  selectedTenant: any;
  visibleTenantId: string | null = null;
  partners: any = [];

  constructor(
    private ngxService: NgxUiLoaderService,
    private oidcService: OidcAuthService,
    private identityService: IdentityAdminService,
    private toastMessage:ToastrService
  ){}

  ngOnInit(): void {
    this.data$ = this.identityService.data$.subscribe((data) => {
      if (Object.keys(data).length) {
        console.log('user profile data:', data);

        this.userProfile = data?.userProfile;
        this.partners = data?.partners;

        this.selectedPartner = this.identityService.getDropdownSelection("selectedPartner");
        this.selectedTenant = this.identityService.getDropdownSelection("selectedTenant");
      }
    });
  }

  navigateToProfile() {
    window.open("https://identity-sts.identity.illumato.com/manage", "blank");
  }

  navigateToPassword() {
    window.open(
      "https://identity-sts.identity.illumato.com/manage/changePassword",
      "blank"
    );
  }

  async logout() {
    this.ngxService.start();
    await this.oidcService.logout();
    // await this.oidcService.logoutWithoutRedirect();

    const language = localStorage.getItem("language");
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    localStorage.clear();

    if (language != null && selectedLanguage != null) {
      localStorage.setItem("language", language);
      localStorage.setItem("selectedLanguage", selectedLanguage);
    }
    // this.router.navigate(['/login']);
  }


  async onDropdownChange(key: string, value: any) {
    await this.identityService.handleDropdownChange(key, value);
    // await this.identityService.updateData(this.partners);
  }

  reset() {
    localStorage.clear();
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.data$?.unsubscribe();
  }
  copyToClipboard(event,text: string): void {
    event.stopPropagation();
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastMessage.success('Copied !')

  }
  


  onEyeClick(event: Event, tenantId: string): void {
    event.stopPropagation();
    this.visibleTenantId = this.visibleTenantId === tenantId ? null : tenantId;
  }
}
