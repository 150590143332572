import axios from 'axios';
import { Injectable } from '@angular/core';
import { OidcAuthService } from './oidc/oidc-auth.service';
import { ToastrService as ToasterService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { devToolNotifify } from '../helper/devToolNotifiyHelper';
import { environment } from '../../environments/environment';
import {  getLocalStorage } from '../helper/functions';

// axios.defaults.baseURL = environment.GetBaseURL;
// axios.defaults.headers['x-api-key'] = environment.xApiKey;
// @ts-ignore
axios.defaults.headers['hasLoading'] = true;
// @ts-ignore
axios.defaults.headers.common.toaster = true;

@Injectable({
  providedIn: 'root',
})
export class AxiosInterceptorService {
  userAuthenticated = false;

  constructor(
    private oidcAuthService: OidcAuthService,
    private toaster: ToasterService,
    private ngxService: NgxUiLoaderService
  ) {
    this.oidcAuthService.loginChanged.subscribe((res: boolean) => {
      this.userAuthenticated = res;
    });

    axios.interceptors.request.use(
      (config: any) => {
        const method = config.method.toLocaleLowerCase();
        if (config.url.includes('translation')) {
          config.baseURL = environment.TranslationService;
        } else if (config.url.includes('UserProfiles')) {
          config.baseURL = environment.IdentityAdminUrl;
        } else if (config.url.includes('processes')) {
          config.baseURL = environment.ReadBaseURL;
        } else {
          // append this query parameter in all API's
          config.params = {
            ...config.params,
            PartnerId: getLocalStorage('selectedPartner'),
            TenantId: getLocalStorage('selectedTenant'),
            EnvironmentId: getLocalStorage('selectedEnvironment'),
          };

          switch (method) {
            case 'get': {
              config.baseURL = environment.ReadBaseURL;

              break;
            }

            case 'post': {
              config.baseURL = environment.WriteBaseUrl;

              // append this parameters in all POST methods
              config.data = {
                ...config.data,
                PartnerId: getLocalStorage('selectedPartner'),
                TenantId: getLocalStorage('selectedTenant'),
                EnvironmentId: getLocalStorage('selectedEnvironment'),
              };
              break;
            }

            case 'put': {
              config.baseURL = environment.WriteBaseUrl;
              // append this parameters in all PUT methods
              config.data = {
                ...config.data,
                PartnerId: getLocalStorage('selectedPartner'),
                TenantId: getLocalStorage('selectedTenant'),
                EnvironmentId: getLocalStorage('selectedEnvironment'),
              };
              break;
            }

            case 'delete': {
              config.baseURL = environment.WriteBaseUrl;
              break;
            }
          }
        }

        if (this.userAuthenticated) {
          config.headers.Authorization =
            this.oidcAuthService.getAuthorizationHeaderValue();
          config.headers.tenantId = getLocalStorage('selectedTenant');
          config.headers.environmentId = getLocalStorage('selectedEnvironment');
        }

        if (config.headers.hasLoading) {
          this.ngxService.start();
        }

        return config;
      },
      (error: any) => {
        // console.log('error : ', error);
        this.toaster.error(error && error.message, 'Error', {});
        this.ngxService.stop();
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response: any) => {
        // console.log('response : ', response);
        const method = response.config.method.toLocaleLowerCase();
        const url = response.config.url.toLocaleLowerCase();

        if (!response.config.headers.toaster) {
          console.log('toaster disabled for this call');
        } else {
          // api returns 200 but in response it sends not 200 if any error so

          if (response.data.statusCode === 200) {
            switch (method) {
              case 'post': {
                if (url !== 'cache') {
                  this.toaster.success('Saved Successfully');
                }
                break;
              }
              case 'put': {
                this.toaster.success('Updated Successfully');
                break;
              }
              case 'delete': {
                this.toaster.success('Deleted Successfully');
                break;
              }
              default: {
              }
            }
          } else {
            response.data.errors?.forEach((err) => {
              if (err['message']) {
                console.error(
                  `Request failed with status code: ${response.data.statusCode} due to ''${err['message']}'`
                );
                return;
              }
              console.error(
                `Request failed with status: ${response.data.statusCode}`
              );
            });
          }
        }

        this.ngxService.stop();
        return response.data;
      },
      (error: any) => {
        this.ngxService.stop();
        console.log('FAILED REQUEST DETAILS', error?.response);

        devToolNotifify(this.toaster);

        // error handling
        const { status, message } = error.response;
        const method = error?.config?.method.toLowerCase(); // GET means list API calls
        const notFound = (error.code = 'ERR_NETWORK');
        const emptyResponse = !error?.response;
        const data = error?.response.data

        if (emptyResponse || (method === 'get' && notFound)) {
          this.ngxService.stop();
          console.log('Response Empty or Not Found!');
          return;
        }

        if (status === 401) {
          this.toaster.warning('Refreshing..', 'Session Expired', {});
          try {
            this.oidcAuthService.refreshToken();
          } catch (e) {
            console.info(`Refresh Token Updated (with some issues)`);
          }
        } else if (status && status != 200 || status != 401 ) {
          
          let message = data.message;
          if (data.errors) {
            const entries = Object.entries(data.errors);
            entries.forEach((err: any) => {
              message = err[0] + ':' + err[1][0];
            });
          }
          this.toaster.error(message, `Request failed with status code: ${status}`);
          console.error(`Request failed with status code: ${status}`);
        } else {
          this.toaster.error(message || 'Internal Server', 'Error');
        }

        this.ngxService.stop();
        return Promise.reject(error);
      }
    );
  }
}
