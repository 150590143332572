import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-disable-trigger',
  templateUrl: './disable-trigger.component.html',
  styleUrls: ['./disable-trigger.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class DisableTriggerComponent implements OnInit {
  @Output() disable = new EventEmitter<any>();
  @Input() title?: string;
  @Input() displayClass?: string;
  @Input() btnClass?: string;
  @Input() iconClass?: string;
  @Input() tooltipText?: string;
  @Input() tooltipPosition?: string;
  @Input() textOnly?: boolean;

  @Input() message?: string;

  modalRef: any;
  bClass: string = '';
  iClass: string = '';

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    this.textOnly = false; // default value

    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    if (!this.title) {
      this.title = 'Disable Trigger';
    }

    if (!this.message) {
      this.message = 'Would you like to disable this?';
    }

    if (!this.btnClass) {
      this.bClass = 'btn-danger';
    } else {
      this.bClass = this.btnClass;
    }

    if (!this.iconClass) {
      this.iClass = 'fa fa-trash-alt';
    } else {
      this.iClass = this.iconClass;
    }
  }

  confirmation(content) {
    this.modalRef = this.modalService.open(content);
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  async confirmed() {
    try {
      await this.disable.emit();
      this.modalRef.close();
    } catch {}
  }
}
